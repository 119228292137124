import axios, { AxiosInstance } from 'axios'
import { appliancepb } from '../../services/protobuf-models/appliance-ms-protobuf-models'
import { isValidGUID } from '../validation-utils'
import { Writer } from 'protobufjs'
import { itempb } from '../../services/protobuf-models/item-ms-protobuf-models'
import {
  ParsedFilter,
  SingleKeyCardQueryParams,
  TIcemakerAddon,
  Tenant,
} from '../shared-types'
import pickBy from 'lodash.pickby'
import { store } from 'src/store'
import { ApiMessage } from 'src/components/adminForms/types'
import { arrayToCSV } from 'src/components/adminForms/helpers'
import { getErrorData, setPurchaseError } from '../helper'
import { TKitSize } from '../shared-types'
import ErrorHandlerRegistry from '../error-registry/error-registry'
import { ContractJSClient } from '@ftdr/contract-js-client'
import { TokenHandlingHttpClient, ProtobufHttpClient } from '@ftdr/http-utils'
//import {errors} from "../error-registry/errors" //uncomment when we ready with configuration and pass errors as second param in to registry constructor

export const registry = new ErrorHandlerRegistry(undefined, {})

const ARRAY_BUFFER = 'arraybuffer'
// hardcoding this for the moment, if making it dynamic is needed then we could consume from Item
// https://gitlab.com/ftdr/software/data-file-manager/-/blob/master/prod/general/item/TradeMapping.csv
const APPLIANCE_TRADE_ID = '5d6351ba-cda5-42f4-b5ae-352b2cd6a8c6'
const EMPTY_ADDRESS_ID = '00000000-0000-0000-0000-000000000000'

// TODO:send the interface to a declaration file and the function to a helper maybe
interface DecodableProtobufMessage<T> {
  decode: (reader: Uint8Array, length?: number) => T
  encode: (message: T) => Writer
}
const decode = <T>(data: ArrayBuffer, model: DecodableProtobufMessage<T>) => {
  return model.decode(new Uint8Array(data))
}

const encode = <T>(data: T, model: DecodableProtobufMessage<T>) => {
  const encoded = model.encode(data).finish()
  return encoded.buffer.slice(
    encoded.byteOffset,
    encoded.byteOffset + encoded.byteLength
  )
}

interface IParams {
  category?: string
  trim_kit_size?: TKitSize
  oim?: string | boolean
}
interface Config {
  ENV: string
  TAELIUM_ENV: string
  AZURE_CLIENT_ID: string
  APPLIANCE_BASE_URL: string
  ITEM_BASE_URL: string
  CONTRACT_BASE_URL: string
  TENANT: string
  PAYPAGE_ID_AHS: string
  IFRAME_URL: string
  CSS_FILE_NAME_AHS: string
  MERCHANT_ID_AHS: string
  PAYMENT_MFE_URL: string
  CONTINUE_REPLACEMENT: boolean
  QUIQ_CHAT_SCRIPT_URL: string
  QUIQ_CHAT_CONTACT_POINT: string
}

class Api {
  private contractServise: ContractJSClient = {} as ContractJSClient
  private applianceInstance: AxiosInstance = axios.create({})
  private itemInstance: AxiosInstance = axios.create({})
  // private contractInstance: AxiosInstance = axios.create({})
  public idToken: string = ''
  public config: Config = {
    // defaults for local
    ENV: process.env.REACT_APP_ENV,
    TAELIUM_ENV: process.env.REACT_APP_TAELIUM_ENV,
    AZURE_CLIENT_ID: process.env.REACT_APP_AZURE_CLIENT_ID,
    APPLIANCE_BASE_URL: process.env.REACT_APP_APPLIANCE_BASE_URL,
    ITEM_BASE_URL: process.env.REACT_APP_ITEM_BASE_URL,
    CONTRACT_BASE_URL: process.env.REACT_APP_CONTRACT_BASE_URL,
    TENANT: process.env.REACT_APP_TENANT,
    PAYPAGE_ID_AHS: process.env.REACT_APP_PAYPAGE_ID_AHS,
    IFRAME_URL: process.env.REACT_APP_IFRAME_URL,
    CSS_FILE_NAME_AHS: process.env.REACT_APP_CSS_FILE_NAME_AHS,
    MERCHANT_ID_AHS: process.env.REACT_APP_MERCHANT_ID_AHS,
    PAYMENT_MFE_URL: process.env.REACT_APP_PAYMENT_MFE_URL,
    CONTINUE_REPLACEMENT:
      process.env.REACT_APP_CONTINUE_REPLACEMENT === 'on' ? true : false,
    QUIQ_CHAT_SCRIPT_URL: process.env.REACT_APP_QUIQ_CHAT_SCRIPT_URL,
    QUIQ_CHAT_CONTACT_POINT: process.env.REACT_APP_QUIQ_CHAT_CONTACT_POINT,
  }
  async contractServiceInit() {
    const tokenHandlingHttpClient = new TokenHandlingHttpClient(this.idToken)
    const protoBuffHandlingHttpClient = new ProtobufHttpClient(
      tokenHandlingHttpClient
    )
    this.contractServise = new ContractJSClient(
      this.config.PAYMENT_MFE_URL,
      protoBuffHandlingHttpClient
    )
  }
  async loadConfig() {
    let config = this.config
    try {
      const { data } = await axios.get<Config>('/config')
      config = data
      this.config = data
    } catch (err) {
      console.warn(
        'There was an error loading the config via nginx, defaulting to local'
      )
    }

    const headers: any = {
      Tenant: config.TENANT,
      'Content-Type': 'application/x-protobuf',
    }

    this.applianceInstance = axios.create({
      baseURL: config.APPLIANCE_BASE_URL,
      responseType: ARRAY_BUFFER,
      headers,
      transformRequest: (data, headers) => {
        const dispatchTenant = store.getState().survey.agentSurvey.dispatch
          ?.tenantAbbreviation
        if (dispatchTenant) {
          headers.Tenant = dispatchTenant
        }
        if (this.idToken) {
          headers.Authorization = `Bearer ${this.idToken}`
        }
        return data
      },
    })
    this.itemInstance = axios.create({
      baseURL: config.ITEM_BASE_URL,
      responseType: ARRAY_BUFFER,
      headers,
      transformRequest: (data, headers) => {
        if (this.idToken) {
          headers.Authorization = `Bearer ${this.idToken}`
        }
        return data
      },
    })

    //Contract MS
    //  this.contractInstance = axios.create({
    //    baseURL: config.CONTRACT_BASE_URL,
    //    responseType: ARRAY_BUFFER,
    //    headers,
    //    transformRequest: (data, headers) => {
    //      headers.Accept = 'application/x-protobuf'
    //      if (this.idToken) {
    //        headers.Authorization = `Bearer ${this.idToken}`
    //      }
    //      return data
    //    },
    //  })

    this.setErrorInterceptors(registry.resposeErrorHandler.bind(registry))
  }

  setErrorInterceptors(onRejected: (error: any) => any) {
    this.applianceInstance.interceptors.response.use(undefined, onRejected)
    this.itemInstance.interceptors.response.use(undefined, onRejected)
  }
  async getContractInfo(contractId: string) {
    //  const data1 = await this.contractInstance.post('/v2/search', {
    //    contractSearchID: { contractIDs: [contractId] },
    //  })
    const {
      data: { successResponse },
    } = await this.contractServise.getContracts({
      contractSearchID: { contractIDs: [contractId] },
      responseFields: [1],
    })

    return successResponse
  }
  //admin page

  async getAdminData(endpoint: string) {
    try {
      const {
        data,
        headers,
        status,
      } = await this.applianceInstance.get(endpoint, { raw: true })
      const fileName =
        headers['content-disposition']?.replace(/^attachment;filename=?/, '') ||
        ''
      if (status === 204) {
        throw new Error('No content')
      }
      return {
        data,
        fileName,
      }
    } catch (error: any) {
      throw new Error(error.message)
    }
  }
  async adminCache(
    cache: string,
    key: string,
    action: 'refresh' | 'delete' = 'refresh'
  ): Promise<ApiMessage> {
    switch (action) {
      case 'refresh':
        try {
          const data = await this.applianceInstance.put(
            `/cache/${cache}/key/${key}`
          )
          if (data.status === 202) {
            return {
              error: false,
              message:
                'Refresh cache request has been accepted, but may take a while to complete',
            }
          } else if (data.status === 204) {
            return {
              error: false,
              message: 'Refresh of selected cache has completed',
            }
          } else {
            return {
              error: true,
              message: `Something went wrong - status code: ${data.status}`,
            }
          }
        } catch (error: any) {
          if (error.message?.endsWith(' 404')) {
            return {
              error: false,
              message: 'This is not a valid action for selected cache',
            }
          }
          return { error: true, message: error.message }
        }
      case 'delete':
        try {
          const data = await this.applianceInstance.delete(
            `/cache/${cache}/key/${key}`
          )
          if (data.status === 202) {
            return {
              error: false,
              message:
                'Delete cache request has been accepted, but may take a few seconds to complete',
            }
          } else if (data.status === 204) {
            return {
              error: false,
              message: 'Deleting of selected cache has completed',
            }
          } else {
            return {
              error: true,
              message: `Something went wrong - status code: ${data.status}`,
            }
          }
        } catch (error: any) {
          if (error.message.endsWith(' 404')) {
            return {
              error: false,
              message: 'This is not a valid action for selected cache',
            }
          }
          return { error: true, message: error.message }
        }
    }
  }
  async adminRefreshCatalog(vendor: string): Promise<ApiMessage> {
    try {
      const data = await this.applianceInstance.put(`/catalog/${vendor}`)

      if (data.status === 202) {
        return {
          error: false,
          message: 'Catalog is refreshed but it can take a while for effective',
        }
      } else {
        return {
          error: true,
          message: `Something went wrong - status code: ${data.status}`,
        }
      }
    } catch (error: any) {
      return { error: true, message: error.message }
    }
  }
  async pushAdminFileFromInputs(endpoint: string, dataToSend: string[]) {
    let file = arrayToCSV(dataToSend)
    const endpointFile = this.config.APPLIANCE_BASE_URL + endpoint
    try {
      await axios.patch(endpointFile, file, {
        headers: {
          Tenant: this.config.TENANT,
          'Content-Type': 'text/csv',
          Authorization: `Bearer ${this.idToken}`,
        },
      })
      return {
        error: false,
        message: 'File is updated',
      }
    } catch (error: any) {
      return { error: true, message: error.message }
    }
  }

  async adminReplaceFile(endpoint: string, file: any) {
    const endpointFile = `${this.config.APPLIANCE_BASE_URL}${endpoint}`

    try {
      await axios.put(endpointFile, file, {
        headers: {
          Tenant: this.config.TENANT,
          'Content-Type': 'text/csv',
          Authorization: `Bearer ${this.idToken}`,
        },
      })
      return {
        error: false,
        message: 'File is updated',
      }
    } catch (error: any) {
      return { error: true, message: error.message }
    }
  }

  //Admin page -end

  //Banners
  async getBanners() {
    try {
      const { data } = await this.applianceInstance.get(`/banners`)

      const res = decode(data, appliancepb.GetBannerResponse)

      return res.banners
    } catch (error: any) {
      throw new Error(error.message)
    }
  }
  async addBanner(data: appliancepb.IUpdateBannersRequest) {
    try {
      const payload = encode(data, appliancepb.UpdateBannersRequest)

      const res = await this.applianceInstance.put('/banners', payload)
      return decode(res.data, appliancepb.UpdateBannersResponse)
    } catch (error: any) {
      throw new Error(error.message)
    }
  }
  async removeBanner(id: string) {
    try {
      return await this.applianceInstance.delete(`banners/${id}`)
    } catch (error: any) {
      throw new Error(error.message)
    }
  }
  //Banners end
  async getItems(itemID?: string) {
    const { GetItemsRequest, GetItemsResponse } = itempb
    const payload = encode(
      { tradeID: APPLIANCE_TRADE_ID, itemID } as itempb.IGetItemsRequest,
      GetItemsRequest
    )
    const { data } = await this.itemInstance.get('/items', {
      params: { proto_body: bufferToBase64(payload) },
    })
    const { items } = decode(data, GetItemsResponse)

    return items
  }

  // type can be either the item name (appliance type name) or the item id (appliance type id)
  async getFilters(type: string): Promise<ParsedFilter[]> {
    const { ApplianceFiltersResponse } = appliancepb
    const { data } = await this.applianceInstance.get('/filters', {
      params: { type },
    })
    const decoded = decode(data, ApplianceFiltersResponse)
    return decoded.filters.map((filter) => {
      if (filter.rangeFilter) {
        return {
          type: 'range',
          name: filter.rangeFilter.name as string,
        }
      }
      return {
        type: 'enum',
        name: filter.enumFilter?.name as string,
        values: filter.enumFilter?.values as string[],
      }
    })
  }

  async getItemServiceFilters(itemId: string) {
    const { ItemAttributeListRequest, ItemAttributeListResponse } = itempb
    const payload = encode(
      { itemID: itemId } as itempb.IItemAttributeListRequest,
      ItemAttributeListRequest
    )
    const { data } = await this.itemInstance.get('/items/attributes', {
      params: { proto_body: bufferToBase64(payload) },
    })
    return decode(data, ItemAttributeListResponse)
  }

  async getReplacementDetails(replacementId: string) {
    const { ReplacementResponse } = appliancepb
    const { data } = await this.applianceInstance.get(
      `/replacements/${replacementId}/details`
    )
    return decode(data, ReplacementResponse)
  }

  async getCartProducts(productID: string, params: SingleKeyCardQueryParams) {
    const { Cart } = appliancepb
    const { data } = await this.applianceInstance.get(
      `/cart-products/${productID}`,
      {
        params,
      }
    )
    return decode(data, Cart)
  }

  async createOrder(id: string, tenant?: string) {
    const headers: any = {
      'Content-Type': 'application/x-protobuf',
      Tenant: tenant || this.config.TENANT,
    }
    const { OutrightPaymentOrderResponse } = appliancepb

    const {
      data: res,
    } = await this.applianceInstance.put(
      `/outright-payments/${id}/order`,
      null,
      { headers }
    )
    return decode(res, OutrightPaymentOrderResponse)
  }

  async createOutrightPayment(
    data: appliancepb.IOutrightPaymentRequest,
    tenant?: string
  ) {
    let config = this.config
    const url = '/outright-payments'
    const headers: any = {
      Tenant: data.vendor === 'PC' ? 'pc' : tenant || config.TENANT,
      'Content-Type': 'application/x-protobuf',
    }
    const payload = encode(data, appliancepb.OutrightPaymentRequest)

    return await this.applianceInstance.post(url, payload, { headers })
  }

  async recordExternalOrder(
    id: string,
    data: appliancepb.IOutrightPaymentPatchRequest,
    tenant?: string
  ) {
    const headers: any = {
      Tenant: tenant || this.config.TENANT,
      'Content-Type': 'application/x-protobuf',
    }
    const { OutrightPaymentOrderResponse } = appliancepb
    const payload = encode(data, appliancepb.OutrightPaymentPatchRequest)
    const {
      data: res,
    } = await this.applianceInstance.patch(
      `/outright-payments/${id}`,
      payload,
      { headers }
    )
    return decode(res, OutrightPaymentOrderResponse)
  }

  async getEcardCSV(params = {}) {
    try {
      const { data, headers, status } = await this.applianceInstance.get(
        '/gift-cards',
        {
          params,
        }
      )
      if (status === 204) {
        throw new Error('There are no data in selected range')
      }
      const fileName =
        headers['content-disposition']?.replace(/^attachment;filename=?/, '') ||
        ''

      return {
        data,
        fileName,
      }
    } catch (error: any) {
      throw new Error(error.message)
    }
  }

  // AHS and HSA
  async createReplacement(
    info: appliancepb.IAHSReplacementOfferRequest,
    trimKitSize?: TKitSize,
    icemakerAddon?: TIcemakerAddon
  ) {
    const {
      AHSReplacementOfferRequest,
      CreateReplacementResponse,
    } = appliancepb

    let params = generateAddonsParams(trimKitSize, icemakerAddon)

    const payload = encode(info, AHSReplacementOfferRequest)
    const { data } = await this.applianceInstance.put(
      `/replacements_offer${params}`,
      payload
    )
    return decode(data, CreateReplacementResponse)
  }

  async putReplacement(
    info: appliancepb.ICreateReplacementRequest,
    trimKitSize?: TKitSize,
    icemakerAddon?: TIcemakerAddon
  ) {
    const { CreateReplacementRequest, CreateReplacementResponse } = appliancepb
    const payload = encode(info, CreateReplacementRequest)
    const params = generateAddonsParams(trimKitSize, icemakerAddon)
    const { data } = await this.applianceInstance.put(
      `/replacements${params}`,
      payload
    )
    return decode(data, CreateReplacementResponse)
  }

  // TODO: `queryParam` should be a params object and passed to axios via `params` config
  async getReplacementProducts(replacementId: string, queryParam: string) {
    const { ProductsResponse } = appliancepb
    const url = `/replacements/${replacementId}/products`
    const { data } = await this.applianceInstance.get(`${url}?${queryParam}`)
    return decode(data, ProductsResponse)
  }

  // TODO: `queryParam` should be a params object and passed to axios via `params` config
  async getProductsByItemTypeId(
    itemTypeId: string,
    partRequestId: string,
    addressId: string,
    queryParam: string = ''
  ) {
    const { ProductsResponse } = appliancepb
    const url = `/products/${itemTypeId}/part-request/${partRequestId}/address/${
      addressId || EMPTY_ADDRESS_ID
    }`

    const { data } = await this.applianceInstance.get(`${url}?${queryParam}`)
    return decode(data, ProductsResponse)
  }

  async getReplacementProduct(
    replacementOrItemId: string, //should only be replacementId
    productId: string,
    addressId: string,
    partRequestId: string,
    dcov?: boolean,
    trimKitSize?: TKitSize,
    icemakerAddon?: TIcemakerAddon
  ) {
    const Product = appliancepb.Product
    const url = `/replacements/${replacementOrItemId}/products/${productId}/address/${
      addressId || EMPTY_ADDRESS_ID
    }/part-request/${partRequestId}`

    let params

    if (trimKitSize) {
      params = pickBy({ dcov, trim_kit_size: trimKitSize })
    } else if (icemakerAddon) {
      params = pickBy({ dcov, oim: !!icemakerAddon })
    } else {
      params = pickBy({ dcov })
    }

    const { data } = await this.applianceInstance.get(url, { params })

    return decode(data, Product)
  }
  async getStatuses() {
    try {
      const { GetReplacementStatusesResponse } = appliancepb
      const { data } = await this.applianceInstance.get('/replacement-statuses')
      const statusesArray = decode(data, GetReplacementStatusesResponse)
      const statuses = statusesArray?.statuses.map((status) => ({
        value: status.status,
        description: status.meaning,
        id: status.status,
      }))
      return { statuses }
    } catch (error: any) {
      console.log(error.message)
      throw new Error(error.message)
    }
  }
  async getModel(model: string, params: IParams) {
    const { ProductsResponse } = appliancepb
    const { data } = await this.applianceInstance.get(`/products/${model}`, {
      params,
    })
    return decode(data, ProductsResponse)
  }

  async getReplacementVerification(replacementId: string) {
    if (!isValidGUID(replacementId)) {
      throw new Error('Invalid Replacement ID')
    }
    await this.applianceInstance.get(`/replacements/${replacementId}/verify`, {
      responseType: 'text',
    })
    return true
  }

  async getReplacements(params = {}, cancelToken?: any) {
    const { FullReplacements } = appliancepb
    try {
      const { data } = await this.applianceInstance.get('/replacements', {
        params,
        cancelToken,
      })

      return decode(data, FullReplacements)
    } catch (error: any) {
      //TODO improve this code
      console.log(error.message)
      throw new Error('Something went wrong. Try again')
    }
  }

  async getPayments(params = {}, cancelToken?: any) {
    const { GetOutrightPaymentResponse } = appliancepb
    try {
      const { data } = await this.applianceInstance.get('/outright-payments', {
        params,
        cancelToken,
      })

      return decode(data, GetOutrightPaymentResponse)
    } catch (error: any) {
      //TODO improve this code
      console.log(error.message)
      throw new Error('Something went wrong. Try again')
    }
  }

  async confirmReplacement(replacementId: string, productId: string) {
    const { ConfirmationResponse } = appliancepb

    const url = `/replacements/${replacementId}/products/${productId}/confirm`

    const { data } = await this.applianceInstance.post(url, null)
    return decode(data, ConfirmationResponse)
  }

  async purchaseReplacement(
    replacementId: string,
    productId: string,
    data: appliancepb.IPostPurchaseRequest
  ) {
    const url = `/replacements/${replacementId}/products/${productId}/purchase`
    const payload = encode(data, appliancepb.PostPurchaseRequest)
    try {
      const res = await this.applianceInstance.post(url, payload)
      return res
    } catch (error: any) {
      const msg = setPurchaseError(getErrorData(error.response.data))
      throw new Error(msg)
    }
  }

  async purchaseAgentReplacement(data: appliancepb.IPostPaymentRequest) {
    const url = '/payments'
    const payload = encode(data, appliancepb.PostPaymentRequest)
    return this.applianceInstance.post(url, payload)
  }

  async patchReplacement(
    replacementId: string,
    patchReplacement: appliancepb.IPatchReplacement,
    tenant: string = Tenant.AHS
  ) {
    const { PatchReplacement } = appliancepb
    const payload = encode(patchReplacement, PatchReplacement)
    const { data } = await this.applianceInstance.patch(
      `/replacements/${replacementId}`,
      payload,
      {
        headers: { Tenant: tenant },
      }
    )
    return data
  }

  async getApplianceCategories(all: boolean = false) {
    const param = all ? { params: { inc: '{1|t|true|y|yes}' } } : {}
    const { Categories } = appliancepb
    const { data } = await this.applianceInstance.get('/categories', param)
    return decode(data, Categories)
  }

  async patchInstaller(
    replacementId: string,
    installer: appliancepb.Installer,
    tenant: string = Tenant.AHS
  ) {
    const { InstallerUpdateRequest } = appliancepb
    const installerUpdateRequest = new InstallerUpdateRequest({ installer })
    const payload = encode(installerUpdateRequest, InstallerUpdateRequest)
    const {
      data,
    } = await this.applianceInstance.put(
      `/replacements/${replacementId}/installer`,
      payload,
      { headers: { Tenant: tenant } }
    )
    return data
  }

  async getDispatchInfo(
    dispatchId: number | string
  ): Promise<appliancepb.IDispatchInfoResponse> {
    const { DispatchInfoResponse } = appliancepb
    const { data } = await this.applianceInstance.get(
      `/dispatches/${dispatchId}`
    )

    return decode(data, DispatchInfoResponse)
  }

  async getVendors() {
    const { Vendors } = appliancepb
    const { data } = await this.applianceInstance.get('/vendors')

    return decode(data, Vendors)
  }

  async getOrderStatus(
    vendor: string,
    orderID: string,
    purchaseOrder?: string
  ) {
    const { OrderStatus } = appliancepb
    const { data } = purchaseOrder
      ? await this.applianceInstance.get(
          `/vendors/${vendor}/orders/000000000/status?purchase_order=${purchaseOrder}`
        )
      : await this.applianceInstance.get(
          `/vendors/${vendor}/orders/${orderID}/status`
        )
    return decode(data, OrderStatus)
  }
  async getPageOrderStatus(replacementId: string) {
    if (!isValidGUID(replacementId)) {
      throw new Error('Invalid Replacement ID')
    }
    const { OrderStatus } = appliancepb
    const { data } = await this.applianceInstance.get(
      `/replacements/${replacementId}/status`
    )
    return decode(data, OrderStatus)
  }

  async setDispatchNote(
    data: appliancepb.CreateReplacementNoteRequest,
    replacementId: number | string
  ) {
    const url = `/replacements/${replacementId}/notes`
    const payload = encode(data, appliancepb.CreateReplacementNoteRequest)

    try {
      const res = await this.applianceInstance.post(url, payload)
      return res
    } catch (error: any) {
      const msg = setPurchaseError(getErrorData(error.response.data))
      throw new Error(msg)
    }
  }
}

function bufferToBase64(buffer: ArrayBuffer) {
  let binary = ''
  const bytes = new Uint8Array(buffer)
  const len = bytes.byteLength
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i])
  }
  return btoa(binary)
}
function generateAddonsParams(
  trimKitSize?: TKitSize,
  icemakerAddon?: TIcemakerAddon
): string {
  let params: string = ''
  if (trimKitSize) {
    params = `?trim_kit_size=${trimKitSize}`
  } else if (icemakerAddon) {
    params = `?oim=${!!icemakerAddon}`
  }
  return params
}
const api = new Api()
export default api
